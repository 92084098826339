import {
    AVERAGE,
    BETFAIR,
    getMoneyLine,
    getOutcomesName,
    MARGIN_LIMIT,
    MarginMethod,
    MARKETS,
    PINNACLE
} from "../../constants/CommonConstants";
import {
    AlertMonitorObject,
    EventBookmakerObject,
    EventMonitorStoredObject,
    EventStoredObject,
    HiddenObject,
    MarketCapObject,
    MoneyLineData,
    MonitorSettingsObject,
    OddsObject,
    SportsFilter
} from "../../@types/response";
import {getSbvMarket} from "./commonUtils";

/**
 * Event is filters by drawer settings
 *
 * @param event
 * @param enabled
 */
export function isVisible(event: EventStoredObject, enabled: SportsFilter): boolean {
    if (Object.keys(enabled).length === 0) return true
    else if (!event.sportId || !event.categoryId || !event.tournamentId) return false
    return !!((enabled[event.sportId.toString()] ?? false) === true ||
        //@ts-ignore
        (enabled[event.sportId.toString()][event.categoryId.toString()] ?? false) === true ||
        //@ts-ignore
        (enabled[event.sportId.toString()][event.categoryId.toString()] ?? []).includes(event.tournamentId));
}

/**
 * Generate Betfair URL
 *
 * @param marketId
 * @param selectionId
 */
export const getBetfairUrl = (marketId: undefined | string, selectionId: undefined | string): null | string => {
    if (!marketId || !selectionId) return null
    return `https://graphs.betfair.com/#/${marketId}/${selectionId}/0`
}

/**
 * Generate Betfair Chart URL
 *
 * @param marketId
 * @param selectionId
 *
 * @return string | null
 */
export const getBetfairChart = (marketId: string, selectionId: string): string | null => {
    return `https://xtsd.betfair.com/LoadRunnerInfoChartAction/?marketId=${marketId}&selectionId=${selectionId}`
}


/**
 * get Average Bookmakers
 *
 * @returns {}
 * @param eventId
 * @param bookmakers
 */
export function getAverageBookmaker(eventId: number, bookmakers: EventBookmakerObject[]) {
    const bookmakersMarkets: Set<string> = new Set(bookmakers.flatMap(b => Object.keys(b.markets)))
    let markets: any = {}
    bookmakersMarkets.forEach((marketKey) => {

        const marketId = marketKey.split('@')[0]
        const signs: string[] = Object.keys(MARKETS[marketId].outcomes)

        let count: { [signId: string]: number } = signs.reduce((obj, item) => {
            return {...obj, [item]: 0};
        }, {}), sum: { [signId: string]: number } = Object.assign({}, count)

        let signAverage: any = {}
        for (const bookmaker of bookmakers) {
            let bookmakerSigns = bookmaker.markets[marketKey]?.sign
            if (!bookmakerSigns) continue
            for (const signId of signs) {
                let backOdd = bookmakerSigns[signId]?.backOdd
                if (backOdd > 1) {
                    sum[signId] += backOdd
                    count[signId]++
                }
            }
        }

        for (const signId of signs) {
            signAverage[signId] = {
                outcomePosition: parseInt(signId),
                backOdd: count[signId] ? Math.round((sum[signId] / count[signId]) * 100) / 100 : 1,
                firstBackOdd: 100,
                updt: new Date().getTime()
            }
        }

        markets[marketKey] = {marketId: marketKey, sign: signAverage}
    })

    return {
        id: eventId.toString(),
        eventId: eventId,
        playability: 1,
        comparisonType: AVERAGE,
        markets
    }
}

/**
 * Get Event fields
 *
 * @returns {object}
 * @param event
 */
export function eventToStore(event: any): EventMonitorStoredObject {
    const markets = event.bookmakers?.[2]?.markets
    let marketCap: any = null
    if (markets) {
        marketCap = {}
        for (let marketId in markets) {
            let marketSign: any = {bMarketId: markets[marketId].betfairMarketId}
            for (let sign in markets[marketId].sign) {
                marketSign[sign] = markets[marketId].sign[sign].betfairSelectionId
            }
            marketCap[marketId] = marketSign
        }
    }
    return {
        _id: event._id,
        sportId: event.sportId,
        sport: event.sport,
        categoryId: event.categoryId,
        category: event.category,
        tournamentId: event.tournamentId,
        tournament: event.tournament,
        name: event.name,
        date: event.date,
        marketCap: marketCap
    }
}

/**
 * Compare Bookmaker
 *
 * @returns {number}
 * @param comparisonOdd
 * @param bookmakerOdd
 * @param margin
 * @param method
 * @param bookings
 * @param signs
 */
function calculateMargin(comparisonOdd: number, bookmakerOdd: number, margin: null | number, method: null | MarginMethod, bookings: number, signs: number): number {
    const boundComparisonOdd = (margin === null) ? comparisonOdd : 100 / ((100 / comparisonOdd) - ((bookings - margin) / signs))
    let result = (method === MarginMethod.NEW) ? ((100 / bookmakerOdd) - (100 / boundComparisonOdd)) : (boundComparisonOdd - bookmakerOdd) / (boundComparisonOdd / 100)
    return Math.round(result * 10) / 10
}

/**
 * Get Odds
 *
 * @param bookmaker
 * @param marketId
 * @returns number
 */
function getBookmakerBooking(bookmaker: any, marketId: string) {
    const signs = bookmaker.markets[marketId].sign
    let bookings = 0
    for (const signId of Object.keys(signs)) {
        bookings += 100 / signs[signId].backOdd
    }
    return bookings
}

/**
 * Get Odds
 *
 * @param bookmaker
 * @param marketId
 * @returns {[]}
 */
function getOdds(bookmaker: any, marketId: string): OddsObject[] {
    const marketOdds = Object.assign({}, bookmaker.markets)[`${marketId}`] || {}
    const odds = Object.assign({}, marketOdds.sign ?? {})

    return Object
        .keys(odds)
        .map((signId) => {
            return {
                signId: parseInt(signId),
                sign: getOutcomesName(marketId, signId),
                odd: Math.round(odds[signId]?.backOdd * 100) / 100 || 0,
                firstBackOdd: odds[signId]?.firstBackOdd || 0
            }
        })
}

/**
 * Process Comparison
 *
 * @param eventId
 * @param comparison
 * @param bookmakers
 * @param settings
 * @param booked
 * @param hides
 * @param marketsCap
 * @param moneyLine
 */
export function processComparison(eventId: number, comparison: EventBookmakerObject, bookmakers: EventBookmakerObject[], settings: MonitorSettingsObject, booked: string[], hides: HiddenObject, marketsCap: MarketCapObject | null = null, moneyLine: MoneyLineData | null = null): AlertMonitorObject[] {
    // @ts-ignore
    return Object.keys(comparison['markets'])
        .filter((marketId: string) => !moneyLine || !getMoneyLine(parseInt(marketId), moneyLine) || getMoneyLine(parseInt(marketId), moneyLine)! > settings.comparisons[PINNACLE.toString()].amountMarket)
        .map((marketId: string) => Object.entries(comparison['markets'][marketId]['sign'])
            .map(([outcomePosition, sign]) => Object.assign(sign, {outcomePosition}))
            .filter((sign: any) => !booked.includes(`${eventId}-${marketId}-${sign.outcomePosition}`)
                && (!marketsCap?.[`${eventId}-${marketId}-${sign.outcomePosition}`] || (
                        marketsCap[`${eventId}-${marketId}-${sign.outcomePosition}`].marketTV > settings.comparisons[comparison.comparisonType.toString()].amountMarket &&
                        marketsCap[`${eventId}-${marketId}-${sign.outcomePosition}`].runnerTV > settings.comparisons[comparison.comparisonType.toString()].amountOutcome
                    )
                ))
            .map((sign: any) => processBookmakers(eventId, comparison, bookmakers, marketId.toString(), sign.outcomePosition.toString(), settings))
            .filter((alert: any) => alert != null && (
                !Object.keys(hides).includes(`${eventId}-${alert.marketId}-${alert.signId}`) || hides[`${eventId}-${alert.marketId}-${alert.signId}`] > alert.margin
            ))
        )
        .filter((x: any) => x.length)
        .flat()
}

/**
 * Process Bookmakers
 *
 * @param eventId
 * @param comparison
 * @param bookmakers
 * @param marketId
 * @param signId
 * @param settings
 */
function processBookmakers(eventId: number, comparison: EventBookmakerObject, bookmakers: EventBookmakerObject[], marketId: string, signId: string, settings: MonitorSettingsObject): null | AlertMonitorObject {
    return bookmakers
        .map((bookmaker: EventBookmakerObject) => {
            //OnlyDrop
            if (!bookmaker.markets || !(marketId in bookmaker.markets)) return null
            //Playability
            if (settings.playability !== 0 && settings.playability !== bookmaker.playability) return null
            // Get comparison odd
            const compOdd = comparison.markets[marketId.toString()].sign[signId.toString()].backOdd
            if (compOdd <= 1) return null
            // Check OnlyDrop
            const opening = comparison.markets[marketId.toString()].sign[signId.toString()].firstBackOdd
            if (settings.onlyDrop && opening && compOdd >= opening) return null
            //Get bookamker odd
            const bookOdd = bookmaker.markets[marketId.toString()].sign[signId.toString()]?.backOdd
            if ((bookOdd && bookOdd < settings.from) || (settings.to !== 1 && bookOdd > settings.to)) return null
            //Apply bound
            const comparisonSettings = settings.comparisons[comparison.comparisonType.toString()]
            const bookins = getBookmakerBooking(comparison, marketId)
            if (comparison.comparisonType === BETFAIR && bookins < 97) return null
            const margin = calculateMargin(
                compOdd,
                bookOdd,
                comparisonSettings.margin,
                comparisonSettings.method,
                bookins,
                Object.keys(comparison.markets[marketId.toString()].sign).length
            )
            if (margin > comparisonSettings.threshold) return null
            const {market, svb} = getSbvMarket(marketId)
            return {
                id: eventId,
                comparisonType: comparison.comparisonType,
                marketId: market,
                signId: parseInt(signId),
                sbv: svb,
                bookmakerId: parseInt(bookmaker.id),
                playability: bookmaker.playability,
                margin: margin,
                odds: getOdds(bookmaker, marketId),
                comparison: getOdds(comparison, marketId)
            }
        })
        .filter((alert: any) => alert?.margin && alert.margin < MARGIN_LIMIT)
        .sort((a: any, b: any) => a.margin - b.margin)
        .find((_, idx) => idx === 0) || null

}