import {call, put} from 'redux-saga/effects';
import {ISkeletonSaga, skeletonSaga} from '../../apis/skeleton/skeletonSaga';
import {GENERIC_ERROR} from '../../constants/ErrorConstants';
// import {HideAlertCall} from '../../apis/player';
import {HidePlayerPayload} from '../../@types/actions';
import {HIDE_ALERT_SUCCESS} from "../../constants/PlayerConstants";
import {HideAlertCall} from '../../apis/player';

function* tryFunction(payload: HidePlayerPayload) {
    yield call(HideAlertCall, payload);
    yield put({
        type: HIDE_ALERT_SUCCESS,
        payload: {[`${payload.eventId}-${payload.playerId}-${payload.marketId}-${payload.sbv}-${payload.signId}`]: payload.margin}
    });
}

export function* hideAlertSaga({payload}: { payload: HidePlayerPayload }): Generator {
    const sagaPayload: ISkeletonSaga = {
        tryApiCall: {function: tryFunction, payload: payload},
        failedType: GENERIC_ERROR
    };
    yield call(skeletonSaga, sagaPayload);
}